<template>
  <div class="custom-toggler">
    <input type="checkbox" :id="id" v-model="computedIsEnabled" class="custom-toggler__input" />
    <label :for="id" class="custom-toggler__label">
      <span class="pop"></span>
    </label>
  </div>
</template>
<script>

export default {
  props: {
    id: String,
    isEnabled: Boolean
  },
  computed: {
    computedIsEnabled: {
      get() {
        return this.isEnabled
      },
      set(value) {
        this.$emit('handle', { value })
      }
    }
  }
}
</script>

<style lang="sass">
.custom-toggler
  background: #fff
  &__input
    position: absolute
    pointer-events: none
    display: none
    opacity: 0
    &:checked
      + label
        .pop
          left: rem(18)
          background-color: $green
          background-image: url(~@/assets/img/icons/check.svg)
  &__label
    background: #FFFFFF
    border: rem(1) solid #D6D6D6
    box-sizing: border-box
    border-radius: rem(100)
    width: rem(40)
    height: rem(24)
    display: block
    position: relative
    cursor: pointer
    .pop
      height: rem(16)
      width: rem(16)
      background-color: $red
      background-position: center
      background-repeat: no-repeat
      border-radius: 50%
      display: block
      position: absolute
      left: rem(4)
      top: 50%
      transition: .3s
      transform: translateY(-50%)
</style>
